import styled, { keyframes } from "styled-components";

const fadeInBody = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;
const fadeInSpan = keyframes`
    0% {
        opacity: 0;
        padding-right: 0px;
    }
    100% {
        opacity: 1;
        padding-right: 20px;
    }
`;
const fadeOutBody = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;
const fadeOutSpan = keyframes`
    0% {
        opacity: 1;
        padding-right: 20px;
    }
    100% {
        opacity: 0;
        padding-right: 0px;
    }
`;
const spinClose = keyframes`
    0% {
        opacity: 1;
        transform: rotate(360deg);
    }
    50% {
        opacity: 0;
    }
`;
const spinOpen = keyframes`
    0% {
        transform: rotate(360deg);
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const Container = styled.div`
    position: ${p => p.toggleAnimation ? "fixed" : "absolute"};
    top: 0;
    left: 0;
    z-index: ${p => p.toggleAnimation ? "10" : "3"};
    width: 100%;
    height: ${p => p.toggleAnimation ? "100dvh" : "fit-content"};
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(${p => p.toggleAnimation ? "15px" : "0"});
    -webkit-backdrop-filter: blur(${p => p.toggleAnimation ? "15px" : "0"});
    transition: all ease-in-out 0.2s;
    `;

export const Header = styled.div`
    width: 100%;
    min-height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    background-color: #0E0E1750;
`;

export const LogoHover = styled.img`
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 30px;
    opacity: 0;
`;
export const Logo = styled.img`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 30px;
`;
export const LogoBox = styled.div`
    position: relative;
    width: fit-content;
    height: 30px;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
    
    &:hover ${Logo} {
        opacity: 0;
        transition: all ease-in-out 0.2s;
    }
    &:hover ${LogoHover} {
        opacity: 1;
        transition: all ease-in-out 0.2s;
    }
`;

export const NavContainer = styled.div`
    width: fit-content;
    height: fit-content;
    display: flex;

    div {
        position: relative;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img {
        position: absolute;
        width: fit-content;
        height: fit-content;
        display: none;
        opacity: 0;
        cursor: pointer;

        @media (max-width: 850px) {
            display: block;
        }
    }

    img:first-child {
        animation: ${p => p.toggleAnimation ? spinClose : spinOpen} 0.2s forwards;
    }
    img:last-child {
        animation: ${p => p.toggleAnimation ? spinOpen : spinClose} 0.2s forwards;
    }
    
    span {
        font-size: 1.2vmax;
        transition: all ease 0.2s;
        color: #FAFAFA;
        a {
            text-decoration: none;
            color: #FAFAFA;
        }
        @media (max-width: 850px) {
            display: none;
        }
        @media (min-width: 1920px) {
            font-size: 16px;
        }
    }

    span:hover {
        color: #37D1DB;
        cursor: pointer;
    }

    span:not(:last-child) {
        margin-right: 25px;
    }
`;

export const Body = styled.div`
    display: none;
    @media (max-width: 850px) {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        border-top: 1px solid #FAFAFA50;
        background-color: #0E0E1750;
        animation-name: ${p => p.toggleAnimation ? fadeInBody : fadeOutBody};
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
        span {
            font-size: 2vmax;
        }

        span:nth-child(1) {
            animation-duration: 0.2s;
        }
        span:nth-child(2) {
            animation-duration: 0.5s;
        }
        span:nth-child(3) {
            animation-duration: 0.7s;
        }
        span:nth-child(4) {
            animation-duration: 0.9s;
        }
        span {
            width: 100%;
            height: 75px;
            display: flex;
            justify-content: end;
            align-items: center;
            padding: 0 0 0 20px;
            border-bottom: 1px solid #FAFAFA50;
            color: #FAFAFA;
            cursor: pointer;
            animation-name: ${p => p.toggleAnimation ? fadeInSpan : fadeOutSpan};
            animation-fill-mode: forwards;
        }
    }
`;