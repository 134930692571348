import styled, { keyframes } from "styled-components";

const subline = keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
`;

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    overflow: clip;
    background-color: #0E0E17;
    `;

export const Projects = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 75px 0 20dvh;
`;
export const ProjectTitle = styled.div`
    width: 100%;
    height: 25dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
        font-size: 3vmax;
        font-weight: bold;
        color: #FAFAFA;

        @media (min-width: 1920px) {
            font-size: 40px;
        }
        @media (max-width: 850px) {
            font-size: 4vmax;
        }
    }
    @media (max-width: 850px) {
        height: fit-content;
        margin-top: calc(5dvh + 90px);
    }
`;
export const ProjectContainer = styled.div`
    width: 100%;
    height: 100dvh;
    display: flex;
    margin-bottom: 10dvh;
    div:first-child {
        position: sticky;
        position: -webkit-sticky;
        top: 25px;
        width: 40%;
        min-width: 40%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        padding: 0 5%;
        span {
            font-size: 3vmax;
            color: #FAFAFA;
            @media (min-width: 1920px) {
                font-size: 40px;
            }
            @media (max-width: 850px) {
                font-size: 4vmax;
                font-weight: bold;
            }
        }
        span:nth-child(3) {
            font-size: 2vmax;
            font-weight: 100;
            margin-bottom: 24px;

            @media (min-width: 1920px) {
                font-size: 24px;
            }
            @media (max-width: 850px) {
                margin-top: 10px;
                font-size: 2.5vmax;
                font-weight: 100;
            }
        }
        div {
            width: fit-content;
            height: fit-content;
            display: flex;
            flex-direction: column;
            transition: all ease-in-out 0.2s;
            cursor: pointer;
            user-select: none;
            span {
                font-size: 2vmax;
                color: #37D1DB;
                @media (min-width: 1920px) {
                    font-size: 24px;
                }
                @media (max-width: 850px) {
                    font-size: 2.5vmax;
                }
            }
            div {
                width: 0%;
                height: 1px;
                background-color: #37D1DB;
            }
        }
        div:hover div {
            animation-name: ${subline};
            animation-duration: 0.2s;
            animation-fill-mode: forwards;
        }
        @media (max-width: 850px) {
            position: static;
            top: 0;
            width: 100%;
        }
    }
    div:last-child {
        height: 100%;
        img {
            height: 100%;
            filter: grayscale(100%);
            cursor: pointer;
            transition: all ease-in-out 0.2s;
            
            &:hover {
                filter: grayscale(0%) drop-shadow(0px 0px 20px #37D1DB);
                @media (max-width: 850px) {
                    filter: none;
                }
            }
            @media (max-width: 850px) {
                width: 100%;
                margin: 30px 5% 0;
                filter: none;
            }
        }
        @media (max-width: 850px) {
            width: 90%;
            height: fit-content;
        }
    }
    @media (max-width: 850px) {
        height: fit-content;
        flex-direction: column;
    }
`;