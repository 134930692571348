import styled, { keyframes } from "styled-components";

const subline = keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
`;

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    overflow: clip;
    background-color: #0E0E17;
`;
export const BlurContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 200dvh;
    overflow: hidden;
`;
export const BlueBlur = styled.img`
    position: absolute;
    z-index: 3;
    top: -40dvh;
    left: calc(0vw - 25vmin);
    width: 80vmin;
    height: 80vmin;
    filter: blur(20px);
    @media (max-width: 850px) {
        
    }
`;
export const PurpleBlur = styled.img`
    position: absolute;
    z-index: 3;
    top: calc(100dvh - 100vmin);
    right: calc(0vw - 100vmin);
    width: 200vmin;
    height: 200vmin;
`;

//HOME

export const Home = styled.div`
    position: relative;
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    overflow: hidden;
    a {
        width: 100%;
        height: fit-content;
        display: flex;
        align-self: center;
        justify-content: center;
        img:last-child {
            height: 5dvh;
            display:none;
        }
        @media (max-width: 850px) {
            img:first-child {
                display: none;
            }
            img:last-child {
                display: block;
            }
        }
    }
`;
export const GridGif = styled.img`
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: 100%;
    height: 50%;
    opacity: 0.1;
`;
export const TextContainer = styled.div`
    position: relative;
    z-index: 4;
    width: 80%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-self: center;
    justify-content: space-between;
    align-content: center;
    margin: 0 10%;
    span {
        max-width: 80%;
        font-size: 1.5vmax;
        font-weight: 200;
        color: #FAFAFA;
        @media (max-width: 850px) {
            max-width: 100%;
            font-size: 2vmax;
        }
    }
    div {
        height: fit-content;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 30px 0 30px;
        span {
            width: fit-content;
            display: flex;
            font-size: 4vmax;
            font-weight: bold;
            white-space: nowrap;
        }
        span:first-child {
        }
        span:last-child {
            font-size: 4vmax;
            background: linear-gradient(90deg, #7E3BD7 38.1%, #37D1DB 90.73%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

    }
    @media (min-width: 1920px) {
        span {
            font-size: 24px;
        }
        span:nth-child(2) {
            font-size: 60px;
        }
        p {
            font-size: 60px;
        }
    }
    
`;
export const MouseGif = styled.img`
    position: absolute;
    z-index: 5;
    bottom: 15px;
    height: 12dvh;
    display: flex;
    align-self: center;
    cursor: pointer;
`;

//PROJECTS

export const Projects = styled.div`
    position: relative;
    z-index: 4;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
`;
export const ProjectTitle = styled.div`
    width: 100%;
    height: 20dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5dvh;
    span:first-child {
        font-size: 1.2vmax;
        color: #37D1DB;
        
        @media (min-width: 1920px) {
            font-size: 16px;
        }
        @media (max-width: 850px) {
            font-size: 2vmax;
            margin-bottom: 10px;
        }
    }
    span:last-child {
        font-size: 3vmax;
        font-weight: bold;
        color: #FAFAFA;

        @media (min-width: 1920px) {
            font-size: 40px;
        }
        @media (max-width: 850px) {
            font-size: 4vmax;
        }
    }
`;
export const ProjectContainer = styled.div`
    width: 100%;
    height: 100dvh;
    display: flex;
    margin-bottom: 10dvh;
    div:first-child {
        position: sticky;
        position: -webkit-sticky;
        top: 25px;
        width: 40%;
        min-width: 40%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        padding: 0 5%;
        span {
            font-size: 3vmax;
            color: #FAFAFA;

            @media (min-width: 1920px) {
                font-size: 40px;
            }
            @media (max-width: 850px) {
                font-size: 4vmax;
                font-weight: bold;
            }
        }
        span:nth-child(3) {
            font-size: 1.5vmax;
            font-weight: 300;
            margin-bottom: 24px;

            @media (min-width: 1920px) {
                font-size: 24px;
            }
            @media (max-width: 850px) {
                margin-top: 10px;
                font-size: 2.5vmax;
                font-weight: 100;
            }
        }
        div {
            width: fit-content;
            height: fit-content;
            display: flex;
            flex-direction: column;
            transition: all ease-in-out 0.2s;
            cursor: pointer;
            user-select: none;
            span {
                font-size: 1.5vmax;
                color: #37D1DB;
                @media (min-width: 1920px) {
                    font-size: 24px;
                }
                @media (max-width: 850px) {
                    font-size: 2.5vmax;
                }
            }
            div {
                width: 0%;
                height: 1px;
                background-color: #37D1DB;
            }
        }
        div:hover div {
            animation-name: ${subline};
            animation-duration: 0.2s;
            animation-fill-mode: forwards;
        }
        @media (max-width: 850px) {
            position: static;
            top: 0;
            width: 100%;
        }
    }
    div:last-child {
        height: 100%;
        img {
            height: 100%;
            filter: grayscale(100%);
            cursor: pointer;
            transition: all ease-in-out 0.2s;
            
            &:hover {
                /* box-shadow: 0 0 40px #37D1DB; */
                filter: grayscale(0%) drop-shadow(0px 0px 20px #37D1DB);
                @media (max-width: 850px) {
                    filter: none;
                }
            }
            @media (max-width: 850px) {
                width: 100%;
                margin: 30px 5% 0;
                filter: none;
            }
        }
        @media (max-width: 850px) {
            width: 90%;
            height: fit-content;
        }
    }
    @media (max-width: 850px) {
        height: fit-content;
        flex-direction: column;
    }
`;

//ABOUT ME

export const AboutMe = styled.div`
    width: 100%;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-image: url(${p => p.image});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    & > span {
        margin-bottom: 5dvh;
        font-size: 3vmax;
        font-weight: bold;
        color: #FAFAFA;
        @media (min-width: 1920px) {
            font-size: 40px;
        }
    }
    @media (max-width: 850px) {
        background-image: none;
    }
`;
export const CardContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    height: fit-content;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @media (max-width: 850px) {
        flex-direction: column;
    }
`;
export const Card = styled.div`
    width: 20%;
    max-width: 310px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border-radius: 16px;
    border: 2px solid #FAFAFA20;
    background-color: #FAFAFA15;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    & > img {
        height: 30%;
        margin-bottom: 2dvh;
    }
    @media (max-width: 850px) {
        width: 60%;
        max-width: none;
        height: fit-content;
        margin: 5dvh 0;
    }
    @media (max-width: 499px) {
        width: 90%;
    }
`;
export const CardText = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    span {
        color: #FAFAFA;
    }
    span:first-child {
        margin-bottom: 16px;
        text-align: center;
        font-size: 1.5vmax;
        font-weight: bold;
        @media (min-width: 1920px) {
            font-size: 24px;
        }
        @media (max-width: 850px) {
            font-size: 2.5vmax;
        }
    }
    span:last-child {
        text-align: left;
        font-size: 1.2vmax;
        font-weight: 400;
        @media (min-width: 1920px) {
            font-size: 16px;
        }
        @media (max-width: 850px) {
            font-size: 2vmax;
        }
    }
`;
export const CardItems = styled.div`
    width: 100%;
    height: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5%;
    div {
        width: 100%;
        height: 20%;
        display: flex;
        align-items: center;
        &:not(first-child) {
            margin-top: 5%;
        }
        img {
            height: 100%;
            margin-right: 10px;
            display: flex;
            align-self: center;
            justify-content: center;
        }
        span {
            color: #FAFAFA;
            font-size: 1.2vmax;
            font-weight: 400;

            @media (min-width: 1920px) {
                font-size: 16px;
            }
            @media (max-width: 850px) {
                font-size: 2vmax;
            }
        }
    }
`;

//CONTACT

export const Contact = styled.div`
    position: relative;
    width: 100%;
    height: 100dvh;
    display: flex;
    align-items: center;
`;
export const ContactBlur = styled.div`
    position: absolute;
    z-index: 3;
    bottom: -40dvh;
    left: -40dvh;
    width: 80dvh;
    height: 80dvh;
    border-radius: 100%;
    filter: blur(100px);
    background-color: #7E3BD7;
    @media (max-width: 850px) {
        display: none;
    }
`;
export const ContactContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    height: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 850px) {
        position: relative;
    }
`;
export const ContactImage = styled.div`
    position: relative;
    z-index: 1;
    width: 40%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 100%;
    }
    @media (max-width: 850px) {
        position: absolute;
        width: 100%;
        opacity: 0.3;
    }
`;
export const ContactText = styled.div`
    position: relative;
    z-index: 4;
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    & > *:not(:last-child) {
        margin-bottom: 25px;
    }
    span {
        color: #FAFAFA;
    }
    @media (max-width: 850px) {
        width: 90%;
        height: fit-content;
        justify-content: center;
        margin: 0 5%;
    }
`;
export const ContactTextTitle = styled.span`
    display: flex;
    font-size: 2vmax;
    font-weight: bold;
    span {
        display: flex;
        text-align: start;
        margin-right: 5px;
        font-size: 2vmax;
        white-space: nowrap;
        @media (min-width: 1920px) {
            font-size: 28px;
        }
        @media (max-width: 850px) {
            font-size: 3vmax;
            &:first-child {
                white-space: nowrap;
            }
        }
        &:nth-child(2) {
            color: #37D1DB;
            text-shadow: 0 0 80px #37D1DB;
            filter: drop-shadow(0 0 80px #37D1DB);
        }
        &:nth-child(4) {
            color: #7E3BD7;
            text-shadow: 0 0 80px #7E3BD7;
            filter: drop-shadow(0 0 80px #7E3BD7);
        }
    }
    @media (min-width: 1920px) {
        font-size: 28px;
    }
    @media (max-width: 850px) {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

`;
export const ContactTextRegular = styled.span`
    font-size: 2vmax;
    @media (min-width: 1920px) {
        font-size: 24px;
    }
    @media (max-width: 850px) {
        font-size: 2.5vmax;
    }
`;
export const ContactSocials = styled.div`
    width: fit-content;
    height: 3.3vmax;
    display: flex;
    margin-top: 25px;
    img {
        height: 100%;
        margin-right: 30px;
        transition: all ease-in-out 0.2s;
        cursor: pointer;
        filter: invert(100%) sepia(45%) saturate(0%) hue-rotate(162deg) brightness(104%) contrast(96%);
        &:hover {
            filter: invert(97%) sepia(99%) saturate(3074%) hue-rotate(129deg) brightness(93%) contrast(81%) drop-shadow(0 0 5px purple);
        }
    }
    @media (min-width: 1920px) {
        height: 50px;
    }
    @media (max-width: 850px) {
        height: 5vmax;
    }
`;