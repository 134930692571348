import styled, { keyframes } from "styled-components";

//keyframes
const glitch = keyframes`
    0% {
        opacity: 0;
    }
    89% {
        opacity: 0;
    }
    90% {
        opacity: 1;
    }
    91% {
        opacity: 1;
    }
    92% {
        opacity: 0;
    }
    93% {
        opacity: 0;
    }
    94% {
        opacity: 1;
    }
    95% {
        opacity: 1;
    }
    96% {
        opacity: 0;
    }
    97% {
        opacity: 0;
    }
    98% {
        opacity: 1;
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;
const glow = keyframes`
    0% {
        box-shadow: 0 -4px 12px #FFFFFF, 0 -4px 12px #FFFFFF;
    }
    50% {
        box-shadow: 0 -4px 24px #FFFFFF, 0 -4px 24px #FFFFFF;
    }
    100% {
        box-shadow: 0 -4px 12px #FFFFFF, 0 -4px 12px #FFFFFF;
    }
`;
export const Container = styled.div`
    position: relative;
    z-index: 5;
    height: 30dvh;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background-color: #0E0E17;
    font-weight: lighter;
    & > * {
        color: #fafafa;
    }
    @media (max-width: 850px) {
        height: fit-content;
        flex-direction: column;
    }
`;
export const Border = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 1px;
    background-color: #FAFAFA;
    animation-name: ${glow};
    animation-duration: 2s;
    animation-iteration-count: infinite;

`;
export const LeftText = styled.div`
    width: fit-content;
    height: fit-content;
    text-align: left;
    font-size: 1.5vmax;
    @media (min-width: 1920px) {
        font-size: 24px;
    }
    @media (max-width: 850px) {
        font-size: 2.5vmax;
        text-align: center;
    }
`;
    export const RightText = styled.div`
    width: fit-content;
    height: fit-content;
    text-align: right;
    font-size: 1.5vmax;
    @media (min-width: 1920px) {
        font-size: 24px;
    }
    @media (max-width: 850px) {
        font-size: 2.5vmax;
        text-align: center;
    }
    a {
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: #FAFAFA;
        span {
            text-align: right;
            font-size: 1.2vmax;
            @media (min-width: 1920px) {
                font-size: 24px;
            }
            @media (max-width: 850px) {
                font-size: 2.5vmax;
                text-align: center;
            }
        }
    }
`;
export const Logo = styled.div`
    position: relative;
    height: 100%;
    width: fit-content;
`;
export const LogoDefault = styled.img`
    z-index: 1;
    height: 100%;
    opacity: 1;
`;
export const LogoGlitch = styled.img`
    position: absolute;
    z-index: 2;
    height: 100%;
    opacity: 0;
    animation-name: ${glitch};
    animation-duration: 2.2s;
    animation-iteration-count: infinite;
`;