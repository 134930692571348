import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import * as C from "./styled";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import BlueBlur from "../../assets/icon/purple-blur.svg";
import PurpleBlur from "../../assets/icon/blue-blur.svg";
import GridGif from "../../assets/gif/gif-capa.gif";
import MouseGif from "../../assets/gif/gif-scroll-down.gif";
import MouseGifMobile from "../../assets/gif/gif-scroll-mobile.gif";

import ProjectPicture1 from "../../assets/img/imagemprojeto1.png";
import ProjectPicture2 from "../../assets/img/imagemprojeto2.png";
import ProjectPicture3 from "../../assets/img/imagemprojeto3.png";

import AboutMeBackground from "../../assets/img/imagem-fundo-sobre-mim.png";
import LightIcon from "../../assets/icon/icone-lampada.svg";
import StarIcon from "../../assets/icon/icone-estrela.svg";
import HeartIcon from "../../assets/icon/icone-coracao.svg";
import PinkCheck from "../../assets/icon/pink-check.svg";
import RedCheck from "../../assets/icon/red-check.svg";
import OneIcon from "../../assets/icon/one-blue.svg";
import TwoIcon from "../../assets/icon/two-blue.svg";
import ThreeIcon from "../../assets/icon/three-blue.svg";

import RocketImage from "../../assets/img/imagem-foguete.png";
import LinkedinIcon from "../../assets/icon/icone-linkedin.svg";
import WhatsappIcon from "../../assets/icon/icone-whatsappsvg.svg";
import GmailIcon from "../../assets/icon/icone-gmail.svg";


const Homepage = () => {

    useEffect(()=>{
        document.title = "Elio Ramos | Home"
    },[])

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "auto" });
    }, [pathname])

    const navigate = useNavigate();

    const givenDate = "01/10/2019";
    const calculateYearDifference = () => {
        const currentDate = new Date();
        const [givenDay, givenMonth, givenYear] = givenDate.split('/').map(Number);
        const currentDay = currentDate.getDate();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();
        let yearDifference = currentYear - givenYear;
        if (currentMonth < givenMonth || (currentMonth === givenMonth && currentDay < givenDay)) {
            yearDifference--;
        }
        return yearDifference;
    };

    const handleNavigate = (path) => {
        navigate("/"+path)
    }

    useEffect(()=> {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
        
                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });
    }, [])

    return (
        <C.Container>
            <C.BlurContainer>
                <C.BlueBlur src={BlueBlur}  draggable="false" />
                <C.PurpleBlur src={PurpleBlur}  draggable="false" />
            </C.BlurContainer>
            <C.Home>
                <C.GridGif src={GridGif}  draggable="false" />
                <Header />
                <C.TextContainer>
                    <span>Olá, meu nome é Elio!</span>
                    <div>
                        <span>Sou especialista em&nbsp;</span>
                        <span>UX Design.</span>
                    </div>
                    <span>Com {calculateYearDifference()} anos de experiência em resolução analítica de problemas, mergulho a fundo em dados, estudo o comportamento do usuário com empatia e aplico as melhores práticas de UX e UI.</span>
                </C.TextContainer>
                <a href="#projects">
                    <C.MouseGif src={MouseGif} draggable="false" />
                    <C.MouseGif src={MouseGifMobile} draggable="false" />
                </a>
            </C.Home>
            <C.Projects id="projects">
                <C.ProjectTitle>
                    <span>Agora, a parte boa.</span>
                    <span>Meus projetos</span>
                </C.ProjectTitle>
                <C.ProjectContainer>
                    <div>
                        <span>1</span>
                        <span>Construindo um gerenciador de frotas</span>
                        <span>Web Design • Pesquisa UX</span>
                        <div onClick={()=>{handleNavigate("projetos/construindo-um-gerenciador-de-frotas")}}>
                            <span>Ver projeto &gt;</span>
                            <div />
                        </div>
                    </div>
                    <div>
                        <img src={ProjectPicture1} draggable="false" onClick={()=>{handleNavigate("projetos/construindo-um-gerenciador-de-frotas")}} />
                    </div>
                </C.ProjectContainer>
                <C.ProjectContainer>
                    <div>
                        <span>2</span>
                        <span>Re-design de um sistema de roteirização</span>
                        <span>Web Design • Pesquisa UX</span>
                        <div onClick={()=>{handleNavigate("projetos/re-design-de-um-sistema-de-roteirizacao")}}>
                            <span>Ver projeto &gt;</span>
                            <div />
                        </div>
                    </div>
                    <div>
                        <img src={ProjectPicture2} draggable="false" onClick={()=>{handleNavigate("projetos/re-design-de-um-sistema-de-roteirizacao")}}  />
                    </div>
                </C.ProjectContainer>
                <C.ProjectContainer>
                    <div>
                        <span>3</span>
                        <span>O que é Design Thinking e um exemplo prático na melhora de um setor</span>
                        <span>Facilitação • Liderança</span>
                        <div>
                            <span onClick={()=>{handleNavigate("projetos/o-que-e-design-thinking")}}>Ver projeto &gt;</span>
                            <div />
                        </div>
                    </div>
                    <div>
                        <img src={ProjectPicture3} draggable="false" onClick={()=>{handleNavigate("projetos/o-que-e-design-thinking")}}  />
                    </div>
                </C.ProjectContainer>
            </C.Projects>
            <C.AboutMe image={AboutMeBackground}>
                <span>Sobre mim</span>
                <C.CardContainer>
                    <C.Card>
                        <img src={LightIcon} draggable="false" />
                        <C.CardText>
                            <span>Interessado e curioso</span>
                            <span>Com um MBA em UX Design e uma busca contínua por conhecimento, busco sempre colocar boas ideias em ação.</span>
                        </C.CardText>
                        <C.CardItems>
                            <div>
                                <img src={PinkCheck} draggable="false" />
                                <span>Design System</span>
                            </div>
                            <div>
                                <img src={PinkCheck} draggable="false" />
                                <span>Design Ops</span>
                            </div>
                            <div>
                                <img src={PinkCheck} draggable="false" />
                                <span>Facilitação e liderança</span>
                            </div>

                        </C.CardItems>
                    </C.Card>
                    <C.Card>
                        <img src={StarIcon} draggable="false" />
                        <C.CardText>
                            <span>Experiente em Design</span>
                            <span>Crio experiências que ganham a confiança, lealdade, nutre verdadeiros fãs e que vende bem.</span>
                        </C.CardText>
                        <C.CardItems>
                            <div>
                                <img src={OneIcon} draggable="false" />
                                <span>Pesquisa e benchmark</span>
                            </div>
                            <div>
                                <img src={TwoIcon} draggable="false" />
                                <span>Wireframing e protipação</span>
                            </div>
                            <div>
                                <img src={ThreeIcon} draggable="false" />
                                <span>Testes e métricas</span>
                            </div>

                        </C.CardItems>
                    </C.Card>
                    <C.Card>
                        <img src={HeartIcon} draggable="false" />
                        <C.CardText>
                            <span>Amante da tecnologia</span>
                            <span>Já utilizava computadores antes de aprender a ler. Minha missão é proporcionar experiências tecnológicas incríveis a todos.</span>
                        </C.CardText>
                        <C.CardItems>
                            <div>
                                <img src={RedCheck} draggable="false" />
                                <span>Amo do que faço</span>
                            </div>
                            <div>
                                <img src={RedCheck} draggable="false" />
                                <span>Empático e colaborativo</span>
                            </div>
                            <div>
                                <img src={RedCheck} draggable="false" />
                                <span>Sempre me aprimorando</span>
                            </div>

                        </C.CardItems>
                    </C.Card>
                </C.CardContainer>
            </C.AboutMe>
            <C.Contact>
                <C.ContactBlur />
                <C.ContactContainer>
                    <C.ContactImage>
                        <img src={RocketImage} draggable="false" />
                    </C.ContactImage>
                    <C.ContactText>
                        <C.ContactTextTitle>
                            <span>Em busca de um futuro mais</span>
                            <span>brilhante</span>
                            <span>e</span>
                            <span>inovador?</span>
                        </C.ContactTextTitle>
                        <C.ContactTextRegular>Vamos iniciar essa jornada juntos!</C.ContactTextRegular>
                        <C.ContactTextRegular>Se desejar entrar em contato para discutir uma oportunidade ou ter uma conversa sobre design, sinta-se à vontade para utilizar um dos links abaixo.</C.ContactTextRegular>
                        <C.ContactSocials>
                            <a href="http://www.linkedin.com/in/eliocr" target="_blank">
                                <img src={LinkedinIcon} draggable="false" />
                            </a>
                            <a href="https://wa.link/qd4og8" target="_blank">
                                <img src={WhatsappIcon} draggable="false" />
                            </a>
                            <a href="mailto:elio.c.ramos1@gmail.com" target="_blank">
                                <img src={GmailIcon} draggable="false" />
                            </a>
                        </C.ContactSocials>
                    </C.ContactText>
                </C.ContactContainer>
            </C.Contact>
            <Footer />
        </C.Container>
    );
};

export default Homepage;