import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    min-height: 100dvh;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: #0E0E17;
    span {
        color: #FAFAFA;
    }
`;

export const Content = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 75px 0 20dvh;
    padding: 0 10%;
`;
export const Title = styled.div`
    width: 100%;
    height: 25dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        text-align: center;
        font-size: 3vmax;
        font-weight: bold;
        @media (min-width: 1920px) {
            font-size: 40px;
        }
        @media (max-width: 850px) {
            font-size: 4vmax;
        }
    }
`;
export const MainText = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        span:first-child {
            width: auto;
            font-size: 3vmax;
            font-weight: bold;
            align-self: flex-start;
            @media (min-width: 1920px) {
                font-size: 40px;
            }
        }
        @media (max-width: 850px) {
            flex-direction: column;   
        }
    }
    span {
        width: 70%;
        height: fit-content;
        align-self: flex-end;
        margin-bottom: 5dvh;
        text-align: left;
        font-size: 1.2vmax;
        @media (min-width: 1920px) {
            font-size: 16px;
        }
        @media (max-width: 850px) {
            width: 90%;
            font-size: 2vmax;
            font-weight: 100;
            align-self: start;
        }
    }
    img {
        width: ${p => p.width};
        @media (max-width: 850px) {
            width: 100%;   
        }
    }
    iframe {
        width: 70%;
        max-width: 850px;
        max-height: 600px;
        align-self: center;
        @media (max-width: 850px) {
            width: 100%;   
        }
    }
    a {
        color: #FAFAFA;
        font-size: 1.2vmax;
        @media (min-width: 1920px) {
            font-size: 16px;
        }
        @media (max-width: 850px) {
            font-size: 2vmax;
        }
    }
`;

export const Legend = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2dvh 0 5dvh;
    span {
        width: 100% !important;
        font-size: 0.8vmax !important;
        font-weight: 300 !important;
        align-self: center !important;
        text-align: center !important;
        margin: 0 !important;
        @media (min-width: 1920px) {
            font-size: 12px !important;
        }
        @media (max-width: 850px) {
            font-size: 1.5vmax !important;
        }
    }
`;