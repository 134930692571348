import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import * as C from "./styled";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import Picture1 from "../../../assets/img/imagemprojeto2.png"
import Picture2 from "../../../assets/img/projeto2imagem2.png";
import Picture3 from "../../../assets/img/projeto2imagem3.png";
import Picture4 from "../../../assets/img/projeto2imagem4.png";
import Picture5 from "../../../assets/img/projeto2imagem5.png";

const ReDesign = () => {

    useEffect(()=>{
        document.title = "Elio Ramos | Projeto Re-design de um sistema de roteirização";
    },[])

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "auto" });
    }, [pathname])
    return (
        <C.Container>
            <Header />
            <C.Content>
                <C.Title>
                    <span>Re-design de um sistema de roteirização</span>
                </C.Title>
                <C.MainText>
                    <div>
                        <span>Visão geral</span>
                        <span>
                            Durante o desenvolvimento desse produto, trabalhei com o re-design do módulo de Roteirização da empresa RotaExata. Introduzi a prototipação de telas na rotina de trabalho da empresa e conduzi testes de usabilidade.
                            <br />
                            <br />
                            Atuei como o único designer trabalhando nesse projeto durante o primeiro semestre de 2020, em conjunto de uma equipe composta de um Product Manager e seis desenvolvedores.
                            <br />
                            <br />
                            O módulo Roteirizador consiste em otimizar o uso dos veículos por meio da roteirização de destinos e planejamento de entregas e/ou serviços, aumentando a produtividade e número de entregas que os motoristas realizam.
                        </span>
                    </div>
                    <img src={Picture1} draggable="false" width="60%" style={{ alignSelf: "center" }} />
                    <C.Legend>
                        <span>Rota do motorista.</span>
                        <span>Foto editada, original em Freepik.com</span>
                    </C.Legend>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>O problema</span>
                        <span>
                            Após analisar o escopo do projeto e para quem o produto deveria atender, um grande problema foi encontrado durante a realização do projeto: a heterogeneidade do público alvo vs. a simplicidade necessária.
                            <br />
                            <br />
                            As regras de negócio de uma operação de visitas à clientes de uma empresa por si só é bastante complexo dependendo do segmento. Recriar um produto que tem capacidade de abranger o máximo de operações de diversos segmentos e manter a simplicidade no uso foi o que se fez o meu desafio nesse projeto.
                        </span>
                    </div>
                    <img src={Picture2} draggable="false" width="70%" style={{ alignSelf: "center" }} />
                    <C.Legend>
                        <span>Criação de uma Visita.</span>
                        <span>Imagem própria, Plataforma RotaExata.</span>
                    </C.Legend>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>O objetivo</span>
                        <span>
                            A possibilidade de melhorar o tempo de entregas e economizar combustível realizando menores trajetos é uma melhoria indiscutível para qualquer empresa de qualquer segmento. Para garantir que o produto pudesse ser oferecido para o máximo de segmentos possíveis, a opção de retirar funcionalidades do módulo antigo não era uma opção, restando a mim apenas trabalhar com a melhoria da distribuição das informações.
                            <br />
                            <br />
                            <span>&nbsp; • &nbsp;Objetivo para os usuário: Montar suas rotas de uma maneira simples, cumprindo com os requisitos das suas operações.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Objetivo para a empresa: Aumentar a facilidade de usabilidade, aumentando a aderência de novos clientes ao produto.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Objetivo para o produto: Realizar o re-design do antigo produto.</span>
                        </span>
                    </div>
                    <img src={Picture3} draggable="false" width="70%" style={{ alignSelf: "center" }} />
                    <C.Legend>
                        <span>Três rotas em criação.</span>
                        <span>Imagem própria, Plataforma RotaExata.</span>
                    </C.Legend>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>O processo</span>
                        <span>
                            A criação do novo módulo de Roteirização da empresa RotaExata incluía o processo de re-design dessa parte da plataforma, pois já existia um módulo que montava rotas para visitas a serem realizadas. Uma das maiores queixas dos clientes em relação à plataforma antiga era a falta de intuitividade e necessidade de treinamento para a utilização do módulo.
                            <br /><br />
                            Atuei nas três principais telas desse módulo, sendo elas: a tela principal de criação de visitas a serem roteirizadas, a tela de roteirização dessas visitas e a página responsiva para utilização em smartphones por motoristas para visualização da rota a ser realizada.
                            <br /><br />
                            Durante minha pesquisa inicial, conversei com usuários da plataforma para entender as necessidades das suas operações e quais os principais pontos falhos do fluxo de usabilidade da plataforma que estava em produção. Nesse momento, tentei descobrir em quais pontos eles travavam ou se perdiam, o que resultava nas baixas porcentagens de uso do módulo de roteirização.
                            <br /><br />
                            As suas principais queixas eram:
                            <br />
                            &nbsp; • &nbsp;Após realizar as tarefas iniciais (cadastro das visitas a serem roteirizadas), não sabiam como prosseguir.
                            <br />
                            &nbsp; • &nbsp;Não sabiam o que cada campo durante a criação da rota significava, resultando na criação de rotas com configurações diferentes das suas necessidades de operação.
                            <br />
                            &nbsp; • &nbsp;Muitos cliques desnecessários, repetitivos.
                        </span>
                    </div>
                    <img src={Picture4} draggable="false" width="70%" style={{ alignSelf: "center" }} />
                    <C.Legend>
                        <span>Rota em roteirização.</span>
                        <span>Imagem própria, Plataforma RotaExata.</span>
                    </C.Legend>
                    <span>
                        Após a pesquisa, desenhei um novo fluxo de usabilidade e navegação, contendo menos passos, mas criando aos usuários mais escolhas adicionais de regras de negócio, ou seja, configuração de roteirização.
                        <br />
                        <br />
                        Um padrão que adicionei durante o re-design foram as mensagens de feedback e botões de ajuda em alguns dos campos de formulários que utilizam de jargões da profissão (uma parte dos usuários são pessoas leigas no assunto).
                        <br />
                        <br />
                        Além de um fluxo simplificado, mensagens de feedback, botões de ajuda, outros princípios do grupo Nielsen Norman foram seguidos, como por exemplo: adição de escapes para voltar ou cancelar ações, criação de hierarquias de importância com as cores das fontes e adição de botões de ações para o próximo passo sempre visíveis quando necessário.
                        <br />
                        <br />
                        Após a criação das telas, realizei iterações de testes de usabilidade com 5 usuários e fiz ajustes a partir do feedback dado e das reações tidas por eles durante o uso do protótipo.
                    </span>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>O resultado</span>
                        <span>
                            O feedback recebido dos usuários foi extremamente positivo. Muitos apreciaram as novas funcionalidades do módulo, como a utilização de peso das cargas a serem entregues e as janelas de horário para realizar as visitas.
                            <br /><br />
                            "O agendamento das visitas ficou muito mais simples e rápido, agora passo menos tempo nesse tarefa." — Eric M.
                            <br />
                            Após realizar testes na plataforma em ambiente de produção, o tempo gasto durante o fluxo de cadastro de visitas caiu em 50%.
                            <br />
                            <br />
                            Além do re-design ter cumprido com sucesso a simplificação da utilização da plataforma, ao mesmo tempo que adiciona novas configurações para cumprir regras de negócios de diversos segmentos, o visual das telas foi elogiado pelos usuários.
                        </span>
                    </div>
                    <img src={Picture5} draggable="false" width="70%" style={{ alignSelf: "center" }} />
                    <C.Legend>
                        <span>Tela para os motoristas visualizarem as visitas programadas.</span>
                        <span>Imagem própria, Plataforma RotaExata.</span>
                    </C.Legend>
                    <span>
                        Um ponto que poderia ter sido melhor projetado durante a realização desse trabalho foi o meu julgamento do escopo do produto ao contemplar algumas das regras de negócio menos importantes para o lançamento inicial, as quais poderiam entrar posteriormente em iterações de melhoria.
                        <br />
                        <br />
                        Para as próximas melhorias do produto, novas soluções para abordar ainda mais regras de negócios para os clientes da plataforma devem ser oferecidas. Um exemplo de funcionalidade que não entrou durante o escopo inicial do projeto era operações de segmentos que montam suas rotas em formato de "pac-man", ou seja, rotas que precisam ser visitadas todas as ruas dentro de um determinado bairro ou setor.
                        <br />
                        <br />
                        Os protótipos foram desenvolvidos no programa Figma e podem ser visualizado clicando nos tópicos abaixo:
                        <br />
                        &nbsp; • &nbsp;<a href="https://www.figma.com/proto/IPH48EAGnMF39HD1j20qG4/Agendamento-de-Visitas-(Copy)?node-id=130%3A2441&scaling=scale-down&page-id=0%3A1&starting-point-node-id=130%3A2003" target="_blank">Agendamento de visitas.</a>
                        <br />
                        &nbsp; • &nbsp;<a href="https://www.figma.com/proto/uW55V9p2G6tO4wvG6CPY3o/Roteiriza%C3%A7%C3%A3o-de-Visitas-Agendadas-(Copy)?node-id=1%3A2&scaling=contain&page-id=0%3A1&starting-point-node-id=1%3A2" target="_blank">Marcar visitas para roteirizar.</a>
                        <br />
                        &nbsp; • &nbsp;<a href="https://www.figma.com/proto/IPH48EAGnMF39HD1j20qG4/Agendamento-de-Visitas-(Copy)?node-id=130%3A2441&scaling=scale-down&page-id=0%3A1&starting-point-node-id=130%3A2003" target="_blank">Roteirizar visitas.</a>
                        <br />
                        &nbsp; • &nbsp;<a href="https://www.figma.com/proto/sjAoBHkuHOWLBXEYhukghm/Minha-Rota-(Copy)?node-id=1%3A4&scaling=scale-down&page-id=0%3A1&starting-point-node-id=1%3A4" target="_blank">Visualizar rota planejada - Mobile.</a>
                    </span>
                </C.MainText>
            </C.Content>
            <Footer />
        </C.Container>
    );
};

export default ReDesign;