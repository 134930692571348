import { useEffect, useState } from 'react';
import './App.css';
import AppRoutes from "./routes";

function App() {
  return (
    <AppRoutes />
  );
}

export default App;
