import styled, { keyframes } from "styled-components";

const shiningEffect = keyframes`
    0% {
        filter: drop-shadow(0 0 0px #37D1DB);
    }
    50% {
        filter: drop-shadow(0 0 20px #37D1DB);
    }
    100% {
        filter: drop-shadow(0 0 0px #37D1DB);
    }
`;

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #0E0E17;
`;

// INTRODUTION
export const Introdution = styled.div`
    width: 100%;
    min-height: calc(80dvh - 75px);
    margin-top: 75px;
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;
export const IntroductionTitle = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10dvh 0 25px;
    span:first-child {
        color: #37D1DB;
        font-size: 1.2vmax;
        @media (min-width: 1920px) {
            font-size: 16px;
        }
        @media (max-width: 850px) {
            font-size: 2vmax;
        }
    }
    span:last-child {
        color: #FAFAFA;
        font-size: 3vmax;
        font-weight: bold;
        @media (min-width: 1920px) {
            font-size: 40px;
        }
        @media (max-width: 850px) {
            font-size: 4vmax;
        }
    }
`;
export const IntrodutionContent = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @media (max-width: 850px) {
        flex-direction: column;
    }
`;
export const IntrodutionContentImg = styled.div`
    width: 25%;
    img {
        max-height: 300px;
        max-width: 300px;
        width: 100%;
        animation-name: ${shiningEffect};
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }
    @media (max-width: 850px) {
        width: 50%;
        margin: 3dvh;
    }
    @media (max-width: 499px) {
        width: 80%;
        margin: 3dvh;
    }
`;
export const IntrodutionContentText = styled.div`
    width: 65%;
    height: fit-content;
    span {
        color: #FAFAFA;
        font-size: 1.2vmax;
        @media (min-width: 1920px) {
            font-size: 16px;
        }
        @media (max-width: 850px) {
            font-size: 2vmax;
            font-weight: 200;
        }
    }
    @media (max-width: 850px) {
        width: 100%;
    }
`;

// EXPERIENCES
export const RecentExperiences = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5% 10%;
`;
export const RecentExperiencesTitle = styled.span`
    font-size: 3vmax;
    color: #FAFAFA;
    margin-bottom: 5dvh;
    @media (min-width: 1920px) {
        font-size: 40px;
    }
`;
export const RecentExperiencesContent = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
`;

//Experiences
export const Experiences = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 5dvh 10%;
    overflow: hidden;
    img {
        position: absolute;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        opacity: 0.2;
        @media (max-width: 850px) {
            display: none;
        }
    }
    @media (max-width: 850px) {
        padding: 0 10% 5dvh;
    }
`;
export const ExperiencesBorderTop = styled.div`
    position: absolute;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #0E0E17 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 70%, #0E0E17 100%);
`;
export const ExperiencesTitle = styled.div`
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 10dvh 0;
    font-size: 3vmax;
    font-weight: bold;
    text-align: center;
    color: #FAFAFA;
    @media (min-width: 1920px) {
        font-size: 40px;
    }
    @media (max-width: 850px) {
        margin: 5dvh 0 0;
        font-size: 4vmax;
    }
`;
export const ExperiencesTextContent = styled.div`
    position: relative;
    z-index: 2;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10dvh;
    @media (max-width: 850px) {
        flex-direction: column;
        padding-bottom: 0;
    }
`;
export const ExperiencesText = styled.div`
    width: 25%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    span {
        color: #FAFAFA;
        font-size: 1.2vmax;
        @media (min-width: 1920px) {
            font-size: 16px;
        }
        @media (max-width: 850px) {
            font-size: 2vmax;
            font-weight: 200;
        }
    }
    span:nth-child(1) {
        font-size: 1.5vmax;
        font-weight: bold;
        @media (min-width: 1920px) {
            font-size: 24px;
        }
        @media (max-width: 850px) {
            margin-top: 10%;
            font-size: 2.5vmax;
        }
    }
    span:nth-child(2) {
        color: #DDD;
        @media (max-width: 850px) {
            margin: 2% 0;
            font-weight: 200;
        }
    }
    span:nth-child(3) {
        display: flex;
        margin: 1vmax 0;
        font-weight: bold;
        p {
            margin-left: 5px;
            color: #37D1DB;
            @media (max-width: 850px) {
                font-size: 2vmax;
            }
        }
        @media (min-width: 1920px) {
            margin: 16px 0;
        }
        @media (max-width: 850px) {
            font-size: 2vmax;
        }
    }
    @media (max-width: 850px) {
        width: 90%;
    }
`;

//SKILLS
export const Skills = styled.div`
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10%;
    @media (max-width: 850px) {
        height: fit-content;
        margin: 5dvh 0 20dvh;
    }
`;
export const SkillsTitle = styled.div`
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    margin-bottom: 5dvh;
    color: #FAFAFA;
    font-size: 3vmax;
    font-weight: bold;
    @media (min-width: 1920px) {
        font-size: 40px;
    }
    @media (max-width: 850px) {
        font-size: 4vmax;
    }
`;
export const SkillsContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    @media (max-width: 850px) {
        flex-direction: column;
    }
`;

export const SkillsContent = styled.div`
    width: 25%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    span:first-child {
        margin-bottom: 5dvh;
        font-size: 1.5vmax;
        font-weight: bold;
        @media (min-width: 1920px) {
            font-size: 24px;
        }
        @media (max-width: 850px) {
            margin-bottom: 1dvh;
            font-size: 2.5vmax;
        }
    }
    span {
        font-size: 1.2vmax;
        color: #FAFAFA;
        line-height: 1.8;
        @media (min-width: 1920px) {
            font-size: 16px;
        }
        @media (max-width: 850px) {
            font-size: 2vmax;
            font-weight: 200;
        }
    }
    @media (max-width: 850px) {
        width: 90%;        
        margin-bottom: 5dvh;
    }
`;