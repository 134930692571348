import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import * as C from "./styled";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import Picture1 from "../../../assets/img/projeto3imagem1.png";
import Picture2 from "../../../assets/img/projeto3imagem2.png"

const ReDesign = () => {

    useEffect(()=>{
        document.title = "Elio Ramos | Projeto O que é Design Thinking e um exemplo prático na melhora de um setor";
    },[])

    const handleSourceNavigation = () => {
        window.open("http://www.workovereasy.com/", "_blank").focus();
    }

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "auto" });
    }, [pathname])
    return (
        <C.Container>
            <Header />
            <C.Content>
                <C.Title>
                    <span>O que é Design Thinking e um exemplo prático na melhora de um setor</span>
                </C.Title>
                <C.MainText>
                    <div>
                        <span>Visão geral</span>
                        <span>
                            Design Thinking, é um assunto super em alta no mundo de gestão atualmente, muitas pessoas falam sobre ele, algumas utilizam a metodologia mas poucos realmente o entendem. Ele é mais comumente visto sendo utilizado para a inovação de um produto de software, porém essa linha de pensamento pode ser utilizado para diversas outras inovações, inclusive, em melhorias para setores. Portanto, antes de apresentar o estudo de caso, vamos ver o que podemos obter com ela e no que ela consiste.
                        </span>
                    </div>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>Contextuar</span>
                        <span>
                            Quando princípios de design são aplicados nas áreas de estratégia e inovação da empresa, a taxa de sucesso dela pode aumentar dramaticamente. Isso já foi comprovado quando empresas centradas no design como Apple, Pepsi, Nike e SAP foram analisadas no Design Value Index de 2015 pelo Design Management Institute e Motiv Strategies, e, no estudo dessas empresas, foi visto que o valor de retorno das práticas de design superaram as S&P 500 em 211% em um período de 10 anos.
                            <br />
                            <br />
                            Sendo assim, vemos que metodologias da área de Design são altamente eficientes para melhorarem algo específico dentro de uma empresa, mas é claro, com o tanto que todos os envolvidos saibam as utilizar adequadamente. Mas então, o que é o Design Thinking?
                            <br />
                            <br />
                            O Design Thinking é uma forma de abordar algo a ser melhorado. Porém não é sobre focar no problema, mas sim focar na solução e na ação, envolvendo tanto análise quanto imaginação. Para isso, há cinco grandes passos nesse processo:
                            <br />
                            <span>&nbsp; 1. &nbsp;Empatizar: Esse estágio envolve coletar insights sobre os usuários e tentar entender os seus objetivos, desejos e necessidades.</span>
                            <br />
                            <span>&nbsp; 2. &nbsp;Definir: Nessa fase é necessário identificar qual é o desafio. Quais são os problemas que estamos enfrentando? O que precisa ser resolvido?</span>
                            <br />
                            <span>&nbsp; 3. &nbsp;Idealizar: Aqui, como se é de esperar, pensamos e pesquisamos possíveis soluções que possam resolver os problemas identificados.</span>
                            <br />
                            <span>&nbsp; 4. &nbsp;Prototipar: O quarto estágio é sobre trazer as ideias para a realidade, criando protótipos e/ou funções de negócio para testar as possíveis soluções.</span>
                            <br />
                            <span>&nbsp; 5. &nbsp;Testar: Por fim, testamos o nosso protótipo e/ou funções na prática com as pessoas alvos do nosso projeto. Porém vale notar que essa não é a última etapa do processo: depois disso ainda vamos receber feedback do público, ajustar o que for necessário e testar novamente, se tornando um processo iterativo.</span>
                        </span>
                    </div>
                    <img src={Picture1} draggable="false" width="70%" style={{ alignSelf: "center" }} />
                    <C.Legend>
                        <span>Processo de Design Thinking. <span onClick={()=>{handleSourceNavigation()}}>Fonte</span>.</span>
                    </C.Legend>
                    <span>
                        Nesse estudo, apresento como utilizei as práticas de Design Thinking para melhorar o fluxo de trabalho de um pequeno setor de Desenvolvimento de uma empresa.
                    </span>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>Empatizar</span>
                        <span>
                            No final do ano de 2020, além de continuar com as minhas atividades de UX Design, adquiri mais uma responsabilidade: atuar como o Scrum Master do time de Desenvolvimento da empresa. Por estar no começo da minha jornada profissional, e toda experiência é boa no começo, aceitei a função de ser o facilitador da equipe.
                            <br />
                            <br />
                            Primeiro, decidi escutar o time, fazendo entrevistas de profundidade para obter dados qualitativos com cada um dos integrantes da equipe, com o objetivo de entender quais eram os pontos fortes e fracos do então atual fluxo de trabalho da equipe. Após escutá-los, surgiram alguns pontos de sucesso e outros de falha:
                            <br />
                            <br />
                            Pontos de sucesso:
                            <span>&nbsp; • &nbsp;Amam o ambiente de trabalho — Colaborativo, descontraído, humorado.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Adoram os protótipos para facilitar o desenvolvimento das telas.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Gostam da ferramenta que trabalham — Trello.</span>
                            <br />
                            <br />
                            Pontos de falha:
                            <span>&nbsp; • &nbsp;Em alguns momentos não sabem o porquê de estar fazendo certas atividades.</span>
                            <br />
                            <span>&nbsp; • &nbsp;A comunicação dos setores de CS e Suporte atrapalham o foco na hora de codar.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Algumas atividades têm requisitos malfeitos, que acabam sendo deixadas de lado, impossibilitando o fluxo do card no Scrum board.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Falta de feedback, e quando tem, é pouco empático.</span>
                        </span>
                    </div>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>Definir</span>
                        <span>
                            Geralmente, em processos de entrevistas para obter insights de melhoria, são escolhidos somente um ou alguns poucos dos pontos para otimizá-los, porém, como esse era um plano de melhoria contínua durante um período de um ano subsequente à conclusão da pesquisa, pegamos os quatro pontos de falha encontrados para trabalhá-los.
                        </span>
                    </div>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>Idealizar</span>
                        <span>
                            Após estudar mais cada um dos tópicos e conversar novamente com o time para entender como os problemas poderiam ser solucionados, eu defini a seguinte lista de atividades, que eu iria realizar pelo time para facilitar o fluxo de trabalho deles enquanto eles se preocupam exclusivamente no desenvolvimento (codar) das atividades:
                            <br />
                            <br />
                            <span>&nbsp; • &nbsp;Gerenciar o Backlog e definir as sprints.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Clarificar o objetivo de cada atividade para a equipe.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Facilitar a realização das atividades da equipe e o atingimento das metas por eles.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Lembrar que qualidade é mais importante que construção de novos recursos.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Agendar, iniciar e terminar as reuniões da equipe quando necessário.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Revisar as tarefas para garantir a qualidade das releases.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Gerenciar KPIs e feedback do time.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Auxiliar quem está fora da equipe a entender quais de suas interações são úteis e quais não são.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Receber as interações de fora da equipe.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Transformar as novas releases mais visíveis para equipe fora do Dev.</span>
                        </span>
                    </div>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>Prototipar</span>
                        <span>
                            Nem todas as atividades que defini necessitavam de entregáveis concretos, ou seja, conversas frequentes com o time e bastante comunicação bastavam para esses tópicos, porém, para outros foi necessário definir funções de negócio.
                            <br />
                            <br />
                            Para esses outros, fiz um brainstorm com o gestor da minha área para pegar mais alguns insights de como poderíamos realizar alguns desses tópicos, como por exemplo: a definição e implementação das KPIs. Elaboramos quais métricas são importantes para a empresa acompanhar em relação aos entregáveis da equipe, e, no final, ficou decidido que as métricas mais importantes eram relacionadas ao prazo: atividades concluídas, atividades concluídas com retrabalho e atividades não concluídas.
                        </span>
                    </div>
                    <img src={Picture2} draggable="false" width="70%" style={{ alignSelf: "center" }} />
                    <C.Legend>
                        <span>Quadro final das KPIs. Imagem própria.</span>
                    </C.Legend>
                    <span>
                        Em relação a definição do Backlog e criação das sprints, uma possível solução seria implementarmos uma nova ferramenta para centralizar todas as pendências do produto em um só lugar: o software “Aha!”. A ferramenta era completa e contemplava todos os requisitos necessários, incluindo uma integração com o software de Scrum board da equipe, o Trello. Sendo assim, resolvemos um dos pontos de falha e mantendo um dos pontos que já eram considerados de sucesso pela equipe.
                    </span>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>Testar</span>
                        <span>
                            Depois de todas as conversas e planejamento, chegou a hora de implantar no dia a dia as mudanças para deixar o setor mais fluido e com mais resultados e também deixar as pessoas mais contentes e satisfeitas com as rotinas de trabalho.
                            <br />
                            <br />
                            Comecei organizando as sprints e as atividades a serem feitas:
                            <br />
                            <span>&nbsp; • &nbsp;A empresa contratou o software “Aha!” e eu gerenciei o backlog por lá, aperfeiçoando as user stories com os requisitos técnicos e de negócio;</span>
                            <br />
                            <span>&nbsp; • &nbsp;Toda segunda-feira da semana as atividades eram inseridas no Trello, e no mesmo dia cada uma das atividades eram explicadas individualmente para o responsável por ela, explicando tanto os requisitos funcionais quanto o porquê de aquela atividade estar sendo realizada, ou seja, qual valor agregaria para o cliente;
                            </span>
                            <br />
                            <span>&nbsp; • &nbsp;Fiquei também disponível durante toda a execução da sprint para esclarecer quaisquer dúvidas de requisitos que surgissem.
                            </span>
                            <br />
                            <br />
                            Já para as entregas e conclusões das atividades do time:
                            <br />
                            <span>&nbsp; • &nbsp;Revisava junto ao time para garantir que os requisitos técnicos foram atingidos e nenhum bug passasse despercebido (nessa época ainda não tínhamos um analista QA);
                            </span>
                            <br />
                            <span>&nbsp; • &nbsp;Anotava as atividades que foram concluídas com sucesso, as que foram concluídas com retrabalho e quais não foram concluídas, para posteriormente gerar as KPIs do time;</span>
                            <br />
                            <br />
                            E por fim, também realizava as seguintes tarefas:
                            <br />
                            <span>&nbsp; • &nbsp;Montava notas de atualização para repassar ao restante do time todas as minúcias que trabalhamos e melhoramos no sistema, e, quando necessário, também realizava apresentações para os outros setores da empresa;

                            </span>
                            <br />
                            <span>&nbsp; • &nbsp;Trimestralmente, fornecia os feedbacks com base nas KPIs geradas e também em observações de atitudes positivas e negativas que os integrantes tiveram, assim como pontos que poderiam melhorar nas atividades diárias;
                            </span>
                            <br />
                            <span>&nbsp; • &nbsp;Recebia as solicitações de melhoria e correções de bugs que os setores de CS e Suporte repassavam dos clientes, analisando-as, e, quando necessário, adicionando no backlog para futuras sprints.
                            </span>
                            Além dessas mudanças, mantivemos os pontos de sucesso, ou seja, o software de Scrum board — Trello — continuou a ser utilizado, o design dos protótipos dos produtos continuaram a serem feitos para facilitar o desenvolvimento.
                            <br />
                            <br />
                            Em relação ao estado de espírito do ambiente de trabalho, — colaborativo, descontraído, humorado — apesar das novas mudanças, busquei sempre reduzir ao máximo as burocracias para eles, ou seja, não obriguei a fazer nenhuma tarefa adicional para eu manter o controle do backlog, das sprints e das novas funções de negócio. Sendo assim, o ambiente de trabalho continuou a ser leve e prático para o time.
                        </span>
                    </div>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>Idealizar</span>
                        <span>
                            Conforme comentado, a etapa de testes não é necessariamente a última. Após a implementação dessas mudanças, trimestralmente conversas eram realizadas com o time para eles darem feedbacks para os fluxos da empresa, e como eles poderiam ser melhorados, gerando assim, uma melhoria contínua iterativa.                          <br />
                            <br />
                            Apesar de o plano de melhoria ter sido planejado para todo o ano, os processos foram implantados e solidificados em um período de nove meses, ou seja, na nossa terceira iteração de melhoria já não havia mais nenhum dos problemas inicialmente levantados. Também vale notar que não havia mais nenhum problema organizacional que fosse considerado crítico para o time.
                            <br />
                            <br />
                            Para a empresa, antes dessas novas implementações o setor era considerado o gargalo do fluxo da empresa. Porém, com o aumento da produtividade e qualidade, ele deixou de ser o elo mais fraco.
                            <br />
                            <br />
                            A partir desse novo modelo de fluxo no setor, os integrantes se sentiram menos frustrados com a rotina de trabalho e mais interessados em continuar trabalhando na empresa. Além disso, o time pôde focar bem mais em questões de melhoria da arquitetura técnica da plataforma, pois o novo fluxo tirou situações que desgastavam o tempo a mente deles.
                        </span>
                    </div>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>Epílogo</span>
                        <span>
                            O Design Thinking é uma ferramenta muito poderosa para resolver problemas, e não precisa ser somente limitada para a inovação de produtos de software, mas pode ser tamém utilizado para diversas outras áreas profissionais e pessoais.
                            <br />
                            <br />
                            Espero que esse artigo tenha te ajudado a entender o conceito de Design Thinking e como podemos aplicar essa metodologia para melhorias de processos organizacionais.
                        </span>
                    </div>
                </C.MainText>
            </C.Content>
            <Footer />
        </C.Container>
    );
};

export default ReDesign;