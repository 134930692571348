import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as C from "./styled";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Profile from "../../assets/img/profile.png";

import BackgroundExperiences from "../../assets/img/pattern-linhas-sobre-mim.jpg";

const AboutMe = () => {

    useEffect(()=>{
        document.title = "Elio Ramos | Sobre mim";
    },[])

    const { pathname } = useLocation();

    useEffect(()=>{
        window.scrollTo({top: 0, behavior: "auto"});
    },[pathname])

    return (
        <C.Container>
            <Header />
            <C.Introdution>
                <C.IntroductionTitle>
                    <span>Esse sou eu!</span>
                    <span>Eu sou o Elio!</span>
                </C.IntroductionTitle>
                <C.IntrodutionContent>
                    <C.IntrodutionContentImg>
                        <img src={Profile} draggable="false" alt="Foto de perfil" />
                    </C.IntrodutionContentImg>
                    <C.IntrodutionContentText>
                        <span>
                            Em meu tempo livre, gosto de assistir séries, jogar videogames e fazer carinho nos meus dois gatos.
                            <br />
                            <br />
                            Minha paixão profissional envolve tecnologia, ou melhor, fazer os outros gostarem de usar tecnologia tanto quanto eu. Meu método para esse objetivo é: construir aplicações fáceis de usar e que resolvam problemas reais de forma definitiva e inovadora.
                            <br />
                            <br />
                            Estou constantemente aprendendo coisas novas e sempre que possível aplicando os novos conhecimentos adquiridos em soluções para tarefas do meu dia a dia, sejam elas profissionais, sociais, intelectuais ou afetivas.
                            <br />
                            <br />
                            Ao longo do meu trabalho, penso tanto no contexto geral quanto nos pequenos detalhes de cada um dos meus projetos. Também gosto de trabalhar em produtos de ponta a ponta, ou seja, da idealização até o desenvolvimento e entrega dele.
                            <br />
                        </span>
                    </C.IntrodutionContentText>
                </C.IntrodutionContent>
            </C.Introdution>
            <C.Experiences>
                <C.ExperiencesBorderTop/>
                <img src={BackgroundExperiences} draggable="false" />
                <C.ExperiencesTitle>
                    Experiências mais recentes
                </C.ExperiencesTitle>
                <C.ExperiencesTextContent>
                    <C.ExperiencesText>
                        <span style={{color: "#37D1DB"}}>UX Designer Pleno</span>
                        <span>SoftExpert</span>
                        <span>Fev 2023 - <p>Atualmente</p></span>
                        <span>• Tudo o que já fazia como Júnior na SoftExpert, e mais:</span>
                        <span>• Mentorar UX Designers júniors da equipe.</span>
                        <span>• Realizar o Onboarding de novos integrantes.</span>
                        <span>• Realizar o processo de Design Review.</span>
                        <span>• Aprimorar os processos e fluxos de trabalho do setor de UX da empresa.</span>
                        <span>• Liderar Workshops de design internamente.</span>
                    </C.ExperiencesText>
                    <C.ExperiencesText>
                        <span>UX Designer Jr.</span>
                        <span>SoftExpert</span>
                        <span>Set 2021 - Fev 2023</span>
                        <span>• Criação de roteiro e condução de pesquisas com usuários e stakeholders.</span>
                        <span>• Realização de benchmarks para ideações dos projetos.</span>
                        <span>• Elaboração de protótipos de alta, média e baixa fidelidade.</span>
                        <span>• Testes de usabilidade com usuários internos e externos da empresa.</span>
                        <span>• Planejar e conduzir dinâmicas de co-criação com outros UX Designers.</span>
                        <span>• Planejar e conduzir dinâmicas de design critique.</span>
                        <span>• Realizar apresentações e conversas frequentes de Design Collabs.</span>
                        <span>• Acompanhamento da documentação de finalização de projeto e implementação junto aos desenvolvedores.</span>
                        <span>• Auxílio na evolução e manutenção do design system interno.</span>
                    </C.ExperiencesText>
                    <C.ExperiencesText>
                        <span>UX Designer Jr.</span>
                        <span>RotaExata</span>
                        <span>Out 2019 - Set 2021</span>
                        <span>• Análise de Requisitos para definição dos detalhes das funcionalidades do software.</span>
                        <span>• Criação de soluções de melhoria de usabilidade mapeando o fluxo de navegabilidade do software.</span>
                        <span>• Criação e definição de Personas e Proto-Personas.</span>
                        <span>• Prototipagem de telas.</span>
                        <span>• Testes de usabilidade com usuários.</span>
                        <span>• Testes para melhoria de conversão: Testes A/B, Testes de card-sorting.</span>
                        <span>• Apresentações e treinamentos internos sobre novas funcionalidades do produto.</span>
                        <span>• Atuar como facilitador, auxiliando os times para que façam o trabalho da melhor e mais eficiente forma possível.</span>
                        <span>• Gerir o mapeamento de processos e interações técnicas do software.</span>
                    </C.ExperiencesText>
                </C.ExperiencesTextContent>
            </C.Experiences>
            <C.Skills>
                <C.SkillsTitle>Minhas habilidades</C.SkillsTitle>
                <C.SkillsContainer>
                    <C.SkillsContent>
                        <span>UI/UX</span>
                        <span>Design de interface de usuários</span>
                        <span>Design de experiência de usuários</span>
                        <span>Pesquisa de UX</span>
                        <span>Fluxo de usuários</span>
                        <span>Personas</span>
                        <span>Wireframing</span>
                        <span>Visual Design</span>
                        <span>Interaction Design</span>
                        <span>Prototipação</span>
                    </C.SkillsContent>
                    <C.SkillsContent>
                        <span>Habilidades pessoais</span>
                        <span>Atitude positiva</span>
                        <span>Proficiência em resolução de problemas</span>
                        <span>Boas habilidades de comunicação</span>
                        <span>Bem organizado</span>
                        <span>Confiável</span>
                        <span>Idiomas: Português e Inglês</span>
                    </C.SkillsContent>
                    <C.SkillsContent>
                        <span>Interesses</span>
                        <span>Música Lo-Fi e épicas</span>
                        <span>Video-games</span>
                        <span>Séries, animes e filmes</span>
                        <span>Musculação e academia</span>
                        <span>Arte digital</span>
                        <span>Tatuagens</span>
                    </C.SkillsContent>
                </C.SkillsContainer>
            </C.Skills>
            <Footer />
        </C.Container>
    );
};

export default AboutMe;