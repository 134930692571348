import * as C from "./styled";

import LogoDefault from "../../assets/icon/logo-default.png";
import LogoGlitch from "../../assets/icon/logo-glitch.png";

const Footer = () => {
    return(
        <C.Container>
            <C.Border/>
            <C.LeftText>
                Elio Corrêa Ramos
                <br/>
                Joinville, SC, Brasil
            </C.LeftText>
            <C.Logo>
                <C.LogoGlitch src={LogoGlitch}  draggable="false" />
                <C.LogoDefault src={LogoDefault}  draggable="false" />
            </C.Logo>
            <C.RightText>
                <a href="mailto:elio.c.ramos1@gmail.com" target="_blank">
                    <span>
                        elio.c.ramos1@gmail.com
                    </span>
                </a>
                <br/>
                <a href="tel:+5547992057513" target="_blank">
                    <span>
                        +55 47 992057513
                    </span>
                </a>
            </C.RightText>
        </C.Container>
    );
}

export default Footer;