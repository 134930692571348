import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as C from "./styled";

import Logo from "../../assets/icon/logo.svg";
import LogoHover from "../../assets/icon/logo-hover.svg";
import Menu from "../../assets/icon/icone-menu.svg";
import Close from "../../assets/icon/icone-x.svg";

const Header = () => {

    const [toggle, setToggle] = useState(false);
    const [toggleAnimation, setToggleAnimation] = useState(null);

    const navigate = useNavigate();
    const pathname = window.location.pathname;

    const handleToggle = () => {
        if(null){
            setToggleAnimation(true)
        }
        if(toggle){
            setTimeout(()=>{
                setToggle(false)
            }, 200)
        } else {
            setToggle(true)
        }
        setToggleAnimation(!toggleAnimation)
    }

    const handleNavigare = (path) => {
        navigate(path)
    }

    const handleResumeDownload = () => {
        window.open("https://drive.google.com/file/d/1wlE4T94QqzeJ7qjeSXMitH61oWKwFOEE/view", "_blank").focus();
    }

    return(
        <C.Container toggleAnimation={toggleAnimation}>
            <C.Header>
                <C.LogoBox onClick={()=>{handleNavigare("/")}}>
                    <C.Logo src={Logo}  draggable="false" />
                    <C.LogoHover src={LogoHover}  draggable="false" />
                </C.LogoBox>
                <C.NavContainer toggleAnimation={toggleAnimation}>
                    <div>
                        {toggleAnimation === null && <img src={Menu} draggable="false" alt="Menu" onClick={()=>{handleToggle()}} 
                         style={{animationPlayState: toggleAnimation === null ? "paused" : "running"}} 
                        />}
                        {toggleAnimation !== null && <img src={Menu} draggable="false" alt="Menu" onClick={()=>{handleToggle()}} 
                         style={{animationPlayState: toggleAnimation === null ? "paused" : "running"}} 
                        />}
                        {toggleAnimation !== null && <img src={Close} draggable="false" alt="Fechar" onClick={()=>{handleToggle()}}
                          style={{animationPlayState: toggleAnimation === null ? "paused" : "running"}} 
                        />}
                    </div>
                    <span
                        style={{color: `${pathname === "/" ? "#37D1DB" : "#FAFAFA"}`}}
                        onClick={()=>handleNavigare("/")}
                    >Home</span>
                    <span
                        style={{color: `${pathname === "/sobre-mim" ? "#37D1DB" : "#FAFAFA"}`}}
                        onClick={()=>handleNavigare("/sobre-mim")}
                    >Sobre mim</span>
                    <span onClick={()=>{handleResumeDownload()}}>Currículo</span>
                    <span
                        style={{color: `${pathname === "/projetos" ? "#37D1DB" : "#FAFAFA"}`}}
                        onClick={()=>handleNavigare("/projetos")}
                    >Projetos</span>
                </C.NavContainer>
            </C.Header>
            {
                toggle
                &&
                <C.Body toggleAnimation={toggleAnimation}>
                    <span
                        style={{color: `${pathname === "/" ? "#37D1DB" : "#FAFAFA"}`}}
                        onClick={()=>handleNavigare("/")}
                    >HOME</span>
                    <span
                        style={{color: `${pathname === "/sobre-mim" ? "#37D1DB" : "#FAFAFA"}`}}
                        onClick={()=>handleNavigare("/sobre-mim")}
                    >SOBRE MIM</span>
                    <span onClick={()=>{handleResumeDownload()}}>CURRÍCULO</span>
                    <span
                        style={{color: `${pathname === "/projetos" ? "#37D1DB" : "#FAFAFA"}`}}
                        onClick={()=>handleNavigare("/projetos")}
                    >PROJETOS</span>
                </C.Body>
            }
        </C.Container>
    );
};

export default Header;