import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from "./page/Homepage";
import AboutMe from "./page/AboutMe";
import Project from "./page/Project";
import DesignThinking from "./page/Project/DesignThinking";
import FleetManager from "./page/Project/FleetManager";
import ReDesign from "./page/Project/ReDesign";

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Homepage />
                    }
                />
                <Route
                    path="/sobre-mim"
                    element={
                        <AboutMe />
                    }
                />
                <Route
                    path="/projetos"
                    element={
                        <Project />
                    }
                />
                <Route
                    path="/projetos/construindo-um-gerenciador-de-frotas"
                    element={
                        <FleetManager />
                    }
                />
                <Route
                    path="/projetos/re-design-de-um-sistema-de-roteirizacao"
                    element={
                        <ReDesign />
                    }
                />
                <Route
                    path="/projetos/o-que-e-design-thinking"
                    element={
                        <DesignThinking />
                    }
                />
            </Routes>
        </BrowserRouter >
    );
};

export default AppRoutes;