import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as C from "./styled";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import ProjectPicture1 from "../../assets/img/imagemprojeto1.png";
import ProjectPicture2 from "../../assets/img/imagemprojeto2.png";
import ProjectPicture3 from "../../assets/img/imagemprojeto3.png";

const Project = () => {

    useEffect(()=>{
        document.title = "Elio Ramos | Projetos"
    },[])

    const navigate = useNavigate();

    const handleNavigate = (path) => {
        navigate("/"+path)
    }

    const { pathname } = useLocation();

    useEffect(()=>{
        window.scrollTo({top: 0, behavior: "auto"});
    },[pathname])

    return(
        <C.Container>
            <Header />
            <C.Projects>
                <C.ProjectTitle>
                    <span>Meus projetos</span>
                </C.ProjectTitle>
                <C.ProjectContainer>
                    <div>
                        <span>1</span>
                        <span>Construindo um gerenciador de frotas</span>
                        <span>Web Design • Pesquisa UX</span>
                        <div onClick={()=>{handleNavigate("projetos/construindo-um-gerenciador-de-frotas")}}>
                            <span>Ver projeto &gt;</span>
                            <div />
                        </div>
                    </div>
                    <div>
                        <img src={ProjectPicture1} draggable="false" onClick={()=>{handleNavigate("projetos/construindo-um-gerenciador-de-frotas")}} />
                    </div>
                </C.ProjectContainer>
                <C.ProjectContainer>
                    <div>
                        <span>2</span>
                        <span>Re-design de um sistema de roteirização</span>
                        <span>Web Design • Pesquisa UX</span>
                        <div onClick={()=>{handleNavigate("projetos/re-design-de-um-sistema-de-roteirizacao")}}>
                            <span>Ver projeto &gt;</span>
                            <div />
                        </div>
                    </div>
                    <div>
                        <img src={ProjectPicture2} draggable="false" onClick={()=>{handleNavigate("projetos/re-design-de-um-sistema-de-roteirizacao")}} />
                    </div>
                </C.ProjectContainer>
                <C.ProjectContainer>
                    <div>
                        <span>3</span>
                        <span>O que é Design Thinking e um exemplo prático na melhora de um setor</span>
                        <span>Facilitação • Liderança</span>
                        <div onClick={()=>{handleNavigate("projetos/o-que-e-design-thinking")}}>
                            <span>Ver projeto &gt;</span>
                            <div />
                        </div>
                    </div>
                    <div>
                        <img src={ProjectPicture3} draggable="false" onClick={()=>{handleNavigate("projetos/o-que-e-design-thinking")}} />
                    </div>
                </C.ProjectContainer>
            </C.Projects>
            <Footer />
        </C.Container>
    );
};

export default Project;