import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import * as C from "./styled";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import Picture1 from "../../../assets/img/foto-artigo.png";
import Picture2 from "../../../assets/img/foto-artigo2.png"
import Picture3 from "../../../assets/img/foto-artigo3.png"
import Picture4 from "../../../assets/img/foto-artigo4.png"
import Picture5 from "../../../assets/img/foto-artigo5.png"
import Picture6 from "../../../assets/img/foto-artigo6.png"

const FleetManager = () => {

    useEffect(()=>{
        document.title = "Elio Ramos | Projeto Construindo um gerenciador de frotas";
    },[])

    const { pathname } = useLocation();

    useEffect(()=>{
        window.scrollTo({top: 0, behavior: "auto"});
    },[pathname])
    return (
        <C.Container>
            <Header />
            <C.Content>
                <C.Title>
                    <span>Construindo um gerenciador de frotas</span>
                </C.Title>
                <C.MainText>
                    <div>
                        <span>Visão geral</span>
                        <span>
                            No projeto de Gestão de Frotas da empresa RotaExata, realizei entrevistas com equipes internas e externas assim como extensas pesquisas para ajudar a determinar o conteúdo final da aplicação. Fui responsável também pelo UI-Design do produto.
                            <br />
                            <br />
                            Fui o principal designer trabalhando nesse projeto durante o segundo semestre de 2020, em conjunto de uma equipe composta de um Product Manager e cinco desenvolvedores.
                            <br />
                            <br />
                            O projeto de Gestão de frotas tem como intuito facilitar o gerenciamento da frota de uma empresa, concedendo a possibilidade de qualquer pessoa, independente do nível de conhecimento sobre gestão veicular, realizar os acompanhamentos e pendências necessárias dos seus veículos.
                        </span>
                    </div>
                    <img src={Picture1} draggable="false" width="70%" style={{ alignSelf: "center"}} />
                    <C.Legend>
                        <span>Painel Gestão.</span>
                        <span>Foto editada, original em Freepik.com.</span>
                    </C.Legend>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>O problema</span>
                        <span>
                            Realizei uma entrevista interna para entender as principais dores dos clientes quando se fala em gestão de veículos, essas dores incluem ações que desejam ter em seus processos rotineiros e atividades que desejam realizar com uma melhor qualidade. As principais dores são:
                            <br /><br />
                            <span>&nbsp; • &nbsp;Acompanhar os custos dos veículos.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Manter as manutenções em dia.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Concluir os trâmites legais de multas e pagá-las rapidamente.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Manter os documentos dos motoristas e dos veículos em dia.</span>
                            <br />
                            <br />
                            Após realizar as entrevistas e entender a proto-persona do produto, percebemos que o perfil do nosso usuário é um apagador de incêndios, ou seja, somente resolve os problemas que vão surgindo, e com isso, não têm o objetivo de planejar ou prever possíveis oportunidades e problemas. Aproveitando essa descoberta, incluímos funcionalidades que facilitam o trabalho desses usuários e planejamos incluir visões de negócio para nosso usuário ter tomadas de decisões com mais informações.
                        </span>
                    </div>
                    <img src={Picture2} draggable="false" width="80%" style={{ alignSelf: "start" }} />
                    <img src={Picture3} draggable="false" width="80%" style={{ alignSelf: "center" }} />
                    <img src={Picture4} draggable="false" width="80%" style={{ alignSelf: "end" }} />
                    <C.Legend>
                        <span>Personas RotaExata (2020).</span>
                        <span>Imagem própria.</span>
                    </C.Legend>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>O objetivo</span>
                        <span>
                            O software a ser desenvolvido tem o objetivo de ser versátil, multidisciplinar e prático, resolvendo assim as principais dores quando se fala em gestão veicular. Sendo assim, pesquisei profundamente como realizar cada um dos processos de gestão veicular, incluindo o registro de custos, as manutenções veiculares, as multas geradas em trânsito e os documentos dos motoristas e da frota.
                            <br />
                            <br />
                            <span>&nbsp; • &nbsp;Objetivo para os usuário: Conseguir realizar os seus processos de gestão rapidamente e livre de muito conhecimento técnico.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Objetivo para a empresa: Disponibilizar um produto diferenciado no mercado, com informações relevantes e inteligentes.</span>
                            <br />
                            <span>&nbsp; • &nbsp;Objetivo para o produto: Ter o conhecimento teórico de diversos processos de gestão de frotas embutido na inteligência do software.</span>
                        </span>
                    </div>
                    <img src={Picture5} draggable="false" width="70%" style={{ alignSelf: "center"}} />
                    <C.Legend>
                        <span>Anotações do objetivo do produto.</span>
                        <span>Imagem própria.</span>
                    </C.Legend>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>O processo</span>
                        <span>
                            Para ajudar o usuário a ter uma boa experiência devemos pensar com cuidado sobre o conteúdo que será apresentado, visando deixar essa experiência não só completa como eficiente.
                            <br /><br />
                            Para isso, fiz uma pesquisa interna e criei três proto-personas, as quais foi utilizadas como base para melhor definição das funcionalidades e apresentação das informações do produto. Infelizmente, por conta do tempo apertado do projeto, cada proto-persona só pode ser validada com um único usuário.
                            <br /><br />
                            De acordo com as nossas proto-personas e desk-researches , vimos que o produto a ser projetado precisaria ser muito mais automático do que de interação, sendo assim, buscamos criar funcionalidades automatizadas com a utilização de uma inteligência que embutimos no próprio sistema e o consumo de APIs que buscam os respectivos dados que normalmente o usuário precisaria cadastrar manualmente.
                            <br /><br />
                            Alguns exemplos de automatização que idealizamos foram:
                            <br />
                            &nbsp; • &nbsp;Disponibilização das multas de trânsito, concedendo a possibilidade de pagá-la, de conferir se ela pode ser contestada, e de identificação do motorista responsável.
                            <br />
                            &nbsp; • &nbsp;Cadastro periódico de checklists de manutenções preventivas.
                            <br />
                            &nbsp; • &nbsp;Aviso para renovação de documentos da frota, com disponibilização dos boletos para pagamento.
                            <br /><br />
                            Após pesquisar cada tópico e suas respectivas regras de negócio, requisitos e exceções, transcrevi e sistematizei todo o conhecimento adquirido para um documento. Junto disso, montei um moodboard com inspirações a partir de benchmark de concorrentes indiretos para o próximo passo: a ideação e criação das telas.
                        </span>
                    </div>
                    <img src={Picture6} draggable="false" width="70%" style={{ alignSelf: "center"}} />
                    <C.Legend>
                        <span>Moodboard a partir de Benchmark.</span>
                        <span>Imagem própria.</span>
                    </C.Legend>
                    <span>
                        Durante a fase de ideação das telas, fiz rascunhos que foram validados com os stakeholders da empresa. Após ajustes e refinamentos, comecei o processo de prototipação das telas, novamente elas foram validadas, porém, dessa vez passando por diversos testes. Os testes que os protótipos passam foram: de usabilidade para conferir a facilidade de uso do produto, de card-sorting, para definir os principais tópicos que os usuários julgam importante ver primeiro durante a gestão da frota e por testes A/B, para definir o tipo de visualização e número dos quadros na tela Painel Gestão.
                    </span>
                </C.MainText>
                <C.MainText>
                    <div>
                        <span>O resultado</span>
                        <span>
                            Meu maior desafio durante a execução desse projeto foi transformar as dores dos clientes em ferramentas que as solucionam para ele dentro do produto, isso porque não tinha muito domínio sobre como funcionava cada processo de gestão veicular, como planos de manutenção veicular e trâmites legais de multas de proprietários CNPJ. Porém, após pesquisas para entender como funciona e como realizar todas essas atividades da melhor maneira, consegui traduzi-las em uma forma automática para que os desenvolvedores pudessem implementar no código e consequentemente, na inteligência do software.
                            <br /><br />
                            Algo que eu gostaria de ter feito melhor foi a validação das proto-personas no momento de ideação, porém por questões de cronograma do projeto, a validação não pode ser efetivada mais profundamente. Apesar disso, a proto-persona refletiu bem o cliente final e os atendeu bem quando testaram o protótipo, na fase de validação.
                            <br /><br />
                            Em relação às telas, os clientes gostaram de como o sistema ficou muito mais automatizado e com menos necessidade de ação humana sobre ele, se encaixando dentro do dia a dia corrido e atarefado e ainda assim mantendo um alto nível de conservação e cuidado com a frota. Abaixo segue um vídeo mostrando uma pequena parte do protótipo realizado na ferramenta Figma:
                        </span>
                    </div>
                    <iframe src="https://www.youtube.com/embed/McOnJ0e5BQ0?si=kBi6TIZad51JUwoA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <C.Legend>
                        <span>Algumas funcionalidades do Módulo de Gestão.</span>
                        <span>Vídeo próprio.</span>
                    </C.Legend>
                    <span>
                        Para as próximas melhorias do produto, a ideia é incluir funcionalidades que facilitam a tomada de decisão do gestor de frotas, exemplos são:
                        <br />
                        &nbsp; • &nbsp;Indicar o momento certo de se adquirir/vender um veículo;
                        <br />
                        &nbsp; • &nbsp;Saber se é mais lucrativo alugar ou comprar um veículo;
                        <br />
                        &nbsp; • &nbsp;Gerenciamento de seguro veicular.
                        <br /><br />
                        O protótipo pode ser visualizado clicando no tópico abaixo:
                        <br />
                        &nbsp; • &nbsp;<a href="https://www.figma.com/proto/fx7waL70UMqPKfN3chawyY/M%C3%B3dulo-Gest%C3%A3o?node-id=200%3A2&scaling=scale-down&hide-ui=1" target="_blank">Protótipo do Módulo Gestão da RotaExata.</a>
                    </span>
                </C.MainText>
            </C.Content>
            <Footer />
        </C.Container>
    );
};

export default FleetManager;